import React from 'react'
import {BsTwitter, BsInstagram} from 'react-icons/bs';
import {AiFillGithub, AiFillLinkedin} from 'react-icons/ai'
import {FaFacebookF} from 'react-icons/fa';

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
          <a href="https://github.com/Ranjanunicode" target="_blank"><AiFillGithub/></a>
        </div>
        <div>
          <a href="https://www.linkedin.com/in/ranjan-pandit-ba46821b0/" target="_blank"><AiFillLinkedin/></a>
        </div>
        <div>
        <a href="https://twitter.com/RanjanPandit16" target="_blank"><BsTwitter/></a>
        </div>
        <div>
          <a href="https://www.instagram.com/ranjanamp6/" target="_blank"><BsInstagram/></a>
        </div>
    </div>
  )
}

export default SocialMedia