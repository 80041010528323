import React, { useState } from 'react';


import {images} from '../../constants';
import { AppWraper, MotionWrap } from '../../wrapper';
import { client } from '../../client';
// import { urlFor, client } from '../../client';

import './Footer.scss'

function Footer() {

  const [formData, setformData] = useState({name: "", email: ""});
  const [isFormSubmitted, setisFormSubmitted] = useState(false);
  const [loading, setloading] = useState(false);

  const {name, email, message} = formData;

  const handleChangeInput= (e) =>{
    const {name, value} = e.target;

    setformData({ ...formData, [name]: value});
  }

  const handleSubmit = () =>{
    setloading(true);

    const contact = {
      _type: 'contact',
      name: name,
      email: email,
      message: message,
    }

    client.create(contact).then(() =>{
      setloading(false);
      setisFormSubmitted(true);
    })
  }

  return (
    <>
      <h2 className='head-text'>Take a coffe & chat with me</h2>

      <div className='app__footer-cards'>
        <div className='app__footer-card'>
          <img src= {images.email} alt='email'/>
          <a href="mailto:ranjanamp6@gmail.com" className='p-text'>ranjanamp6@gmail</a>
        </div>
        <div className='app__footer-card'>
          <img src= {images.mobile} alt='mobile'/>
          <a href="tel: +917679131428" className='p-text'>+91 7679131428</a>
        </div>
      </div>

      {!isFormSubmitted ? 
      <div className='app__footer-form app__flex'>
        <div className='app__flex'>
          <input className='p-text' type="text" placeholder='Your Name' name='name' value={name} onChange={handleChangeInput} required>
          </input>
        </div>
        <div className='app__flex'>
          <input className='p-text' type="email" placeholder='Your Email' name='email' value={email} onChange={handleChangeInput} required>
          </input>
        </div>
        <div>
          <textarea 
            className='p-text'
            placeholder='Your Message'
            value={message}
            name="message"
            onChange={handleChangeInput}
          >
          </textarea>
        </div>
        <button type='button' className='p-text' onClick={handleSubmit}>{loading ? 'Sending': 'Send Message'}</button>
      </div>
      : <div>
          <h3 className='head-text'>Thank you for getting in touch!</h3>
      </div>}

      <div className="copyright">
          <p className="p-text">@2022-2023 RANJAN</p>
          <p className="p-text">All rights reserved</p>
      </div>
    </>
  )
}

// export default Footer;
export default AppWraper(
  MotionWrap(Footer, 'app__footer'),
  'Contacts',
  'app__whitebg',
);