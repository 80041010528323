import React from 'react'
import {motion} from 'framer-motion'

import { AppWraper } from '../../wrapper'

import {images} from '../../constants'
import './Header.scss'

const scaleVarients ={
  whileInView: {
    scale: [0,1],
    opacity:[0,1],
    transition:{
      duration:1,
      ease:'easeInOut'
    }
  }
}

const Header = () => {
  return (
    <div className='app__header app__flex'>
      {/* first motion div */}
      <motion.div 
        whileInView={{x: [-100,0], opacity:[0,1]}}
        transition={{duration:0.5}}
        className="app__header-info"
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            <span>👋</span>
            <div style ={{margin:20}}>
              <p className='p-text'>Hello, I am </p>
              <h1 className='head-text'>Ranjan</h1>
            </div>
          </div>
          <div className='tag-cmp app__flex'>
          <p className='p-text'>Web Developer </p>
          <p className='p-text'>Freelancer</p>
          <p className='p-text'>Android/IOS Developer</p>
          <p className='p-text'>Python Developer</p>
          </div>
        </div>
      </motion.div>
      {/* second motion div */}
      <motion.div
        whileInView={{opacity:[0,1]}}
        transition={{duration:0.5, delayChildren:0.5}}
        className="app__header-img"
      >
        <img src={images.profile} alt="profile_bg"/>
        <motion.img
          whileInView={{scale:[0,1]}}
          transition={{duration:1, ease:'easeInOut'}}
          className="overlay_circle"
          src={images.circle}
          alt="profile_circle"
        >

        </motion.img>
      </motion.div>
      {/* 3rd motion div */}
      <motion.div
        variants={scaleVarients}
        whileInView={scaleVarients.whileInView}
        className="app__header-circles"
      >
        {[images.flutter, images.react, images.python, images.figma].map((circle,index) =>(
          <div className='circle-cmp app__flex' key={`circle-${index}`}>
            <img src={circle} alt="circle"/>
          </div>
        ))}
      </motion.div>
    </div>
  )
}

export default AppWraper(Header, 'home');